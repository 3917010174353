<template>
<modal name="modal-settingsPaymentFile" with="auto" height="auto" style="z-index: 9999">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-md">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152">ABA File Settings</span>
                                <i class="icon-feather-help-circle text-primary" style="vertical-align: middle;font-size:16px" ng-click="whatIsABA()"></i>
                            </h5>
                            <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
                        </div>
                        <div class="modal-body text-left">
                            <form name="bankSettingForm" class="ng-pristine ng-valid ng-valid-required ng-valid-pattern ng-valid-maxlength ng-valid-minlength">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="bsb">BSB <span class="text-danger">*</span></label>
                                            <input class="form-control error ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required ng-valid-pattern ng-valid-maxlength" placeholder="" type="text" ng-pattern="^[0-9]{6}$" required="" name="bsb" ng-class="{ &#39;is-invalid&#39; : bankSettingForm.bsb.$invalid &amp;&amp; bankSettingForm.bsb.$touched}" id="bsb" ng-model="bankSettings.b_b" ng-blur="checkInstitutionCode()" maxlength="6">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="account-number">Account Number <span class="text-danger">*</span> <small>6~9 digits</small></label>
                                            <input class="form-control error ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required ng-valid-pattern ng-valid-maxlength" placeholder="" type="text" ng-pattern="^[0-9-]{1,9}$" required="" name="accountNumber" ng-class="{ &#39;is-invalid&#39; : bankSettingForm.accountNumber.$invalid &amp;&amp; bankSettingForm.accountNumber.$touched }" ng-model="bankSettings.b_no" maxlength="9">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="account-name">Account Name <span class="text-danger">*</span></label>
                                            <input class="form-control error ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required ng-valid-maxlength" placeholder="" type="text" required="" name="accountName" ng-class="{ &#39;is-invalid&#39; : bankSettingForm.accountName.$invalid &amp;&amp; bankSettingForm.accountName.$touched }" ng-model="bankSettings.b_n" ng-blur="" maxlength="16">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="institution-code">Institution Code <span class="text-danger">*</span> <a href="https://www.thebsbnumbers.com/institution-codes/" target="_blank">Search</a></label>
                                            <input class="form-control error text-uppercase ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required ng-valid-minlength ng-valid-maxlength" placeholder="" type="text" name="institutionCode" ng-class="{ &#39;is-invalid&#39; : bankSettingForm.institutionCode.$invalid &amp;&amp; bankSettingForm.institutionCode.$touched }" ng-model="bankSettings.b_co" ng-minlength="3" required="" maxlength="3">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group" style="margin-bottom: 2px">
                                            <label for="apca-id">APCA User Id <span class="text-danger">*</span> <small>6 digits</small></label>
                                            <input class="form-control error ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required ng-valid-pattern ng-valid-maxlength" placeholder="" type="text" required="" ng-pattern="/\d+/g" name="apcaId" ng-class="{ &#39;is-invalid&#39; : bankSettingForm.apcaId.$invalid &amp;&amp; bankSettingForm.apcaId.$touched }" ng-model="bankSettings.b_id" maxlength="6">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <small>Contact your bank to confirm APCA Id. (Eg. For CBA, use 301500)</small>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button v-on:click="this.closeModal" class="btn btn-light" data-dismiss="modal" type="button"> Close</button>
                            <button v-on:click="this.saveFile" class="btn btn-primary" type="button" ng-disabled="loading || bankSettingForm.$invalid">
                                Save &amp; Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
export default {
    name: "SettingsPaymentFileModal",
    props: [],
    components: {},
    data() {
        return {};
    },
    methods: {
        openModal() {
            this.$modal.show('modal-settingsPaymentFile', {
                data: 'data'
            });
        },
        closeModal() {
            this.$modal.hide('modal-settingsPaymentFile');
        },
        saveFile() {
            this.closeModal()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
