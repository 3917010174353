<template>
  <div class="row">
    <div class="clearfix"></div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <label class="pay_check"
        ><input type="checkbox" v-model="GoIsRecurral" name="" /> Enable
        Recurring Payments</label
      >
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <button
        @click="createRequest()"
        class="btn"
        :class="Plan <= 0 ? 'btn-grey' : 'btn-success'"
        :disabled="Plan <= 0"
        style="margin-left: 15px"
      >
        Initialize Direct Debit
      </button>
      <!-- <button
        :disabled="Plan <= 0"
        class="btn"
        :class="Plan <= 0 ? 'btn-grey' : 'btn-success'"
        @click="createToken"
      >
        Make Payment
      </button> -->
    </div>
  </div>
</template>
<script>
import { INITIATE_GOCARDLESS_PAYMENT } from "@/store/actionsType";
import ApiManager from "@/api/ApiManager";
import * as Utils from "@/utils/Utils";

export default {
  props: ["Plan"],
  data() {
    return {
      //   token: null,
      //   cardNumber: null,
      //   cardExpiry: null,
      //   cardCvc: null,
      GoIsRecurral: false,
      //   cardName: null,
    };
  },
  computed: {},
  beforeDestroy() {},
  mounted() {
    window.addEventListener("focus", this.onWindowsFocus);
  },
  methods: {
    onWindowsFocus() {
      // console.log('Session.has("currenCompanyId")',Session.has("currenCompanyId"))
      this.$emit("GetCurrentSubscription");
    },
    async sendRequestToServer(Plan) {
      var requestModal = {
        planId: Plan,
        isRecurral: this.GoIsRecurral,
        token: "",
        SuccessUrl: ApiManager.getUrlDomainUI() + "/GoCardlessDebitSuccessUrl",
      };
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(INITIATE_GOCARDLESS_PAYMENT, requestModal)
        .then((resp) => {
          this.$root.$refs.AppCyrus.finish();
          //      console.log("serverResponse", resp);
          localStorage.setItem("3iXze89", resp.session_token);
          localStorage.setItem("1lQam60", Plan);
          localStorage.setItem("8rKon25", this.GoIsRecurral);
          //sessi
          window.open(resp.redirect_url, "name"); //,'height=500,width=550'
          // window.$("<a>").prop({
          //                 target: "_blank",
          //                 href: resp.redirect_url
          //             })[0].click();
          Utils.toastInfo("Allow popup to open payment page.")
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    async createRequest() {
      if (this.Plan > 0) {
        this.sendRequestToServer(this.Plan);
      } else {
        Utils.toastError("Plan not selected.");
        return;
      }
    },
  },
};
</script>
