<template>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div id="card-number"></div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div id="card-expiry"></div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div id="card-cvc"></div>
    </div>
    <div id="card-error"></div>
    <div class="clearfix"></div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <button
        class="btn"
        :class="Plan <= 0 ? 'btn-grey' : 'btn-success'"
        @click="createToken">
        Save Card
      </button>
    </div>
  </div>
</template>
<script>
import { SEND_TOKEN_TO_SERVER } from "@/store/actionsType";
import * as Logs  from "@/utils/Logs";
import * as Utils from "@/utils/Utils";

export default {
  props: ["Plan"],
  data() {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardName: null,
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  mounted() {
    const style = {
      base: {
        color: "black",
        fontfamily: "Arial, Helvetica, sans-serif",
        background: "#f7f7f7",
        border: "1px solid #c5c5c5",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },

  methods: {
    async sendTokenToServer(_token, Plan) {
      var requestModal = {
        planId: Plan,
        token: _token,
        isRecurral: true,
        SuccessUrl :""
      };
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(SEND_TOKEN_TO_SERVER, requestModal)
        .then((resp) => {
          this.$root.$refs.AppCyrus.finish();
          // console.log("serverResponse", resp);
          if (resp.status == "succeeded" || resp.status == "active") {
            Utils.toastSuccess("Card saved");
            this.$emit("GetCurrentSubscription");
            this.Plan = 0;
          } else {
            Utils.toastError(resp.status);
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    /**
     * Creates a token using the Stripe API.
     * 
     * @returns {Promise<void>} A promise that resolves when the token is created and sent to the server.
     */
    async createToken() {
      Logs.logDebug("createToken")
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        Logs.logError(error.message, error)
        Utils.toastError(error.message);
        return;
      }

      this.sendTokenToServer(token.id, this.Plan);
    },

  } // end methods
};
</script>

<style scoped>
/* #custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
} */

#card-error {
  color: red;
}
</style>
