<template>
    <div>
        <modal name="modal-settingsAddAccountant" with="auto" height="auto" style="z-index: 2000">
            <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
                <div class="modal-dialog modal-m">
                    <div class="modal-content" uib-modal-transclude="">
                        <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                            <div class="modal-content" style="height: 220px;">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                        <span class="ng-binding">Invite additional user</span>
                                    </h5>
                                    <button aria-label="Close" class="box-title close" data-dismiss="modal" type="button" v-on:click="closeModal()">
                                    </button>
                                </div>
                                <div class="modal-body employee-modal text-left">
                                    <form name="stpForm">
                                        <div class=" row">
                                            <div class="col-sm-12">
                                                <label for="contactName">Email
                                                    <span class="text-danger">*</span>
                                                </label>
                                                <div class="form-group">
                                                    <input v-model="email" class="form-control ng-email" type="text" required name="email">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button v-on:click="closeModal()" class="btn btn-cancel btn-grey">Cancel</button>
                                    <button v-on:click="addAccountant()" class="btn btn-primary" style="margin-left: 15px;">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <!-- popup delete -->
        <div id="popupDeleteAccountant" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
            <div class="ng-confirm ng-confirm-white ng-confirm-type-default">
                <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
                <div class="ng-confirm-scrollpane">
                    <div class="ng-bs3-container container">
                        <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                            <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                                <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box418771" tabindex="-1" style="margin-top: 76.3595px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                    <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                                    <div class="ng-confirm-title-c">
                                        <span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span>
                                        <span class="ng-confirm-title">Delete accountant</span>
                                    </div>
                                    <div class="ng-confirm-content-pane" style="height: 63px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                        <div class="ng-confirm-content" id="ng-confirm-box418771">
                                            <div class="ng-scope">Please note that this accountant data cannot be restored once you delete it. Would you like to proceed?</div>
                                        </div>
                                    </div>
                                    <div class="ng-confirm-buttons">
                                        <button v-on:click="removeAccountant()" type="button" class="btn btn-primary">
                                            <span class="ng-confirm-btn-text">Yes!</span>
                                        </button>
                                        <button v-on:click="hidePopup()" type="button" class="btn btn-default">
                                            <span class="ng-confirm-btn-text">close</span>
                                        </button>
                                    </div>
                                    <div class="ng-confirm-clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Validate from "@/utils/Validate"
export default {
    name: "SettingsAddAccountant",
    props: [],
    components: {},
    data() {
        return {
            email: ""
        };
    },
    methods: {
        openModal() {
            this.$modal.show('modal-settingsAddAccountant', {
                data: 'data'
            });
        },
        closeModal() {
            this.$modal.hide('modal-settingsAddAccountant');
        },
        hidePopup() {
            var elem = window.$('#popupDeleteAccountant')
            elem.addClass("hide-popup")
        },
        removeAccountant() {
            this.hidePopup()
            this.$root.$refs.Setting.removeAccountant()
        },
        addAccountant() {
            if(this.checkValidate()) {
                this.$root.$refs.Setting.addAccountant(this.email)
            }
        },
        checkValidate() {
            if(Validate.isEmpty(this.email)) {
                window.$('.ng-email').addClass('is-invalid')
                return false
            }
            return true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.hide-popup {
    display: none
}
</style>