<template>
<modal name="modal-settingsResetPass" with="auto" height="auto" style="z-index: 100">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="lg" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-m">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152">Reset Password</span>
                            </h5>
                            <button v-on:click="this.closeModal" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"><span aria-hidden="true"></span></button>
                        </div>
                        <form name="stpForm" v-on:submit.prevent="resetPassword()" action="" method="post" class="text-left ng-pristine ng-valid ng-valid-maxlength ng-valid-required ng-valid-email">
                            <div class="modal-body employee-modal text-left">
                                <v-app>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label for="branchNumber">Old Password <span class="text-danger">*</span></label>
                                                <v-text-field v-model="user.oldPassword" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" name="password" class="input-group--focused" @click:append="show1 = !show1" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required outlined></v-text-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label for="contactName">New Password <span class="text-danger">*</span></label>
                                                <v-text-field v-on:keydown.space="(event) => event.preventDefault()"  v-on:keyup="passwordStrength($event)" v-model="user.newPassword" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" name="password" class="input-group--focused" @click:append="show2 = !show2" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required outlined></v-text-field>
                                                 <div id="StrengthDisp"></div>
                                                 <div v-if="submitted && $v.user.newPassword.$error" class="validationError">
                                                    <span v-if="!$v.user.newPassword.required">Password is required</span>
                                                    <span v-if="!$v.user.newPassword.minLength">Password must be at least 8 characters</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label for="contactName">Confirm New Password <span class="text-danger">*</span></label>
                                                <v-text-field v-model="user.confirmPassword" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'" name="password" class="input-group--focused" @click:append="show3 = !show3" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required outlined></v-text-field>
                                               
                                                 <div v-if="submitted && $v.user.confirmPassword.$error"  class="validationError">
                                                    <span v-if="!$v.user.confirmPassword.required">Confirm Password is required</span>
                                                    <span v-else-if="!$v.user.confirmPassword.sameAsPassword">Passwords must match</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-app>
                            </div>
                            <div class="modal-footer">
                                <button v-on:click="this.closeModal" class="btn btn-light" data-dismiss="modal" type="button"> Close</button>
                                <button class="btn btn-primary ng-binding" type="submit" ng-disabled="loading">
                                    <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span> Reset Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import * as Encodes from "@/utils/Encodepassword"
import * as Utils from "@/utils/Utils"

import {
    RESET_PASSWORD
} from "@/store/actionsType";

let strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,})"
);
let mediumPassword = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
);
export default {
    name: "SettingsResetPass",
    props: [],
    components: {},
    data() {
        return {
            show1: false,
            show2: false,
            show3: false,
            user: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            },
            
            submitted: false
        };
        
    },
    validations: {
            user: {
                oldPassword: { required },
                newPassword: { required, minLength: minLength(8) },
                confirmPassword: { required, sameAsPassword: sameAs('newPassword') }
            },
            
        },
    methods: {
        passwordStrength(event) {
      this.strengthChecker(event.target.value);
    },
    strengthChecker(PasswordParameter) {
      let divStrengthDisp = document.getElementById("StrengthDisp");
      if (strongPassword.test(PasswordParameter)) {
        divStrengthDisp.classList = "Strong";
        divStrengthDisp.textContent = "Strong";
      } else if (mediumPassword.test(PasswordParameter)) {
        divStrengthDisp.classList = "Good";
        divStrengthDisp.textContent = "Medium";
      } else {
        divStrengthDisp.classList = "Weak";
        divStrengthDisp.textContent = "Weak";
      }
    },
        openModal() {
            this.$modal.show('modal-settingsResetPass', {
                data: 'data'
            });
        },
        closeModal() {
            this.$modal.hide('modal-settingsResetPass');
        },
        async resetPassword() {
            
            this.submitted = true;
            this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$root.$refs.AppCyrus.start()
                console.log(this.user.oldPassword,this.user.newPassword);
                var oldCode = await Encodes.encryptPass(this.user.oldPassword)
                var newCode = await Encodes.encryptPass(this.user.newPassword)
                if(oldCode != null && newCode != null) {
                    var request = {
                        'oldPassword': oldCode,
                        'newPassword': newCode
                    }
                  //  Logs.json('request', request)
                    this.$store.dispatch(RESET_PASSWORD, request)
                    .then (() => {
                        this.$root.$refs.AppCyrus.finish()
                        Utils.toastSuccess("Reset password successful!")
                        this.closeModal();
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
                } else {
                    this.$root.$refs.AppCyrus.fail()
                }
            
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#StrengthDisp {
  position: relative;
  bottom: 20px;
  height: 3px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 25px;

}
.Weak {
  width: 20%;
  background-color: #dc3545;
 color: #dc3545;
}
.Good {
  width: 70%;
  background-color: #28a745;
 color: #28a745;

}
.Strong {
  width: 100%;
  background-color: #d39e00;
 color: #d39e00;

}
.validationError {
width: 100%;
margin-top:-20px;
font-size: 15px;
color: #e65252;
}

</style>
